<template>
    <div>
        <Header type="1" url="/Applyflow"></Header>
        <div class="content-container">
            <h2>开发中,敬请期待</h2>
        </div>
        <buttom></buttom>
    </div>
</template>

<script>
    import Header from "@/components/common/Header";
    import Buttom from "@/components/common/Buttom";
    export default {
        name: "Applyflow",
        data:function () {
            return {
            }
        },
        components:{
            Header,
            Buttom,
        },
    }
</script>

<style lang="stylus" scoped>
    .content-container
        width 100%
        height 50rem
        box-sizing border-box
        padding-top 10rem
        h2
            text-align center

</style>